export const BankAccounts = {
    "AkbankAccount": {
        image: require('./assets/images/banks/akbank.png'),
        name: "Akbank T.A.Ş.",
        class: "AkbankAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "AlbarakaAccount": {
        image: require('./assets/images/banks/albaraka.png'),
        name: "Albaraka Türk Katılım Bankası",
        class: "AlbarakaAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "DenizbankAccount": {
        image: require('./assets/images/banks/denizbank.png'),
        name: "DenizBank A.Ş.",
        class: "DenizbankAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "GarantiAccount": {
        image: require('./assets/images/banks/garanti.png'),
        name: "T. Garanti Bankası A.Ş.",
        class: "GarantiAccount",
        settings: {
            "accountnumber": {
                title: "Hesap Numarası",
                description: "Kontrol etmek istediğiniz hesap numarasını yazınız.",
                type: "text",
                default: ""
            },
            "branchcode": {
                title: "Şube Kodu",
                description: "Kontrol etmek istediğiniz hesabın bağlı bulunduğu şube kodunu yazınız.",
                type: "text",
                default: ""
            },
            "branchname": {
                title: "Şube Adı",
                description: "Kontrol etmek istediğiniz hesabın bağlı bulunduğu şube adını yazınız.",
                type: "text",
                default: ""
            },
            "iban": {
                title: "IBAN",
                description: "Kontrol etmek istediğiniz banka hesabına ait IBAN bilgisini yazınız.",
                type: "text",
                default: ""
            },
            "currency": {
                title: "Para Birimi",
                description: "Bu banka hesabının para birimini seçiniz.",
                type: "select",
                options: [
                    {value: "TRY", label: "Türk Lirası"},
                    {value: "USD", label: "USD"},
                    {value: "EUR", label: "EUR"},
                    {value: "GBP", label: "GBP"},
                ],
                zIndex:9,
                default: "TRY"
            },
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            },
            "username": {
                title: "Kullanıcı Adı",
                description: "Banka tarafından atanan kullanıcı adını yazınız.",
                type: "text",
                default: ""
            },
            "firmcode": {
                title: "Firma Kodu",
                description: "Banka tarafından atanan firma kodunu yazınız.",
                type: "text",
                default: ""
            }
        }
    },
    "IngAccount": {
        image: require('./assets/images/banks/ingbank.png'),
        name: "ING Bank A.Ş.",
        class: "IngAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "Kuveytturk": {
        image: require('./assets/images/banks/kuveytturk.png'),
        name: "Kuveyt Türk Katılım Bankası A.Ş.",
        class: "KuveytturkAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "QnbFinansbankAccount": {
        image: require('./assets/images/banks/finansbank.png'),
        name: "QNB Finansbank A.Ş.",
        class: "QnbFinansbankAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "TebAccount": {
        image: require('./assets/images/banks/teb.png'),
        name: "Türk Ekonomi Bankası A.Ş.",
        class: "TebAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "IsbankAccount": {
        image: require('./assets/images/banks/isbank.png'),
        name: "Türkiye İş Bankası A.Ş.",
        class: "IsbankAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "Vakifbank": {
        image: require('./assets/images/banks/vakifbank.png'),
        name: "T. Vakıflar Bankası T.A.O.",
        class: "VakifbankAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "YapikrediAccount": {
        image: require('./assets/images/banks/ykb.png'),
        name: "Yapı ve Kredi Bankası A.Ş.",
        class: "YapikrediAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    },
    "ZiraatAccount": {
        image: require('./assets/images/banks/ziraatbank.png'),
        name: "T.C. Ziraat Bankası A.Ş.",
        class: "ZiraatAccount",
        settings: {
            "test_mode": {
                title: "Test modu?",
                description: "Test ortamında çalışmak için aktif ediniz.",
                type: "checkbox",
                default: "1"
            }
        }
    }
}

export function getBankObjectBankAccount(bankClass) {
    return BankAccounts[bankClass]
}
